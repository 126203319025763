<template>
  <div class="row mx-0">
    <div class="col-12 p-0" ref="theImage">
      <Image :imageName="project.imageUrl" :width="theImageWidth" :height="theImageHeight" theClass="img-fluid fill" />
      <!-- <img :src="url + '/static/uploads/images/' + project.imageUrl" class="img-fluid fill" /> -->
    </div>
    <div class="col-12 p-0 title mt-3 mb-2 text-center">
      {{project.name}}
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'ProjectSmallCard',
  props: ['project'],
  components: {
    Image: defineAsyncComponent(() => import('../components/Image.vue'))
  },
  data () {
    return {
      url: process.env.VUE_APP_URL_BASE,
      theImageWidth: 0,
      theImageHeight: 0
    }
  },
  async mounted () {
    await this.getDimensions()
  },
  computed: {
    date () {
      const date = DateTime.fromMillis(this.project.startDate)
      return date.toLocaleString(DateTime.DATE_FULL)
    }
  },
  methods: {
    getDimensions () {
      this.theImageWidth = this.$refs.theImage.clientWidth
      this.theImageHeight = this.$refs.theImage.clientHeight
    },
    goToProject () {
      window.scroll(0, 0)
      this.$router.push('/project/' + this.project.id)
    }
  }
}
</script>

<style scoped>
.fill {
  width: 100%;
  height: 80px;
  object-fit: cover;
  border-radius: 15px;
}
.title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--green-color);
}

</style>
